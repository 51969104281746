$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;

@font-face {
	font-family: "Noto Mono";
	font-weight: $fontWeightRegular;
	font-style: normal;
	src:url("../fonts/NotoMono-Regular.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoMono-Regular.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans";
	font-weight: $fontWeightExtraLight;
	font-style: normal;
	src:url("../fonts/NotoSans-ExtraLight.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-ExtraLight.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans";
	font-weight: $fontWeightLight;
	font-style: normal;
	src:url("../fonts/NotoSans-Light.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-Light.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans";
	font-weight: $fontWeightRegular;
	font-style: normal;
	src:url("../fonts/NotoSans-Regular.woff2") format("woff2") /* Very Modern Browsers */ 
	  	url("../fonts/NotoSans-Regular.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans";
	font-weight: $fontWeightMedium;
	font-style: normal;
	src:url("../fonts/NotoSans-Medium.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-Medium.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans";
	font-weight: $fontWeightSemiBold;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiBold.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiBold.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans";
	font-weight: $fontWeightBold;
	font-style: normal;
	src:url("../fonts/NotoSans-Bold.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-Bold.woff") format("woff"); /* Modern Browsers */
}


@font-face {
	font-family: "Noto Sans SemiCondensed";
	font-weight: $fontWeightExtraLight;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiCondensedExtraLight.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiCondensedExtraLight.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans SemiCondensed";
	font-weight: $fontWeightLight;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiCondensedLight.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiCondensedLight.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans SemiCondensed";
	font-weight: $fontWeightRegular;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiCondensed.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiCondensed.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans SemiCondensed";
	font-weight: $fontWeightMedium;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiCondensedMedium.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiCondensedMedium.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans SemiCondensed";
	font-weight: $fontWeightSemiBold;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiCondensedSemiBold.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiCondensedSemiBold.woff") format("woff"); /* Modern Browsers */
}

@font-face {
	font-family: "Noto Sans SemiCondensed";
	font-weight: $fontWeightBold;
	font-style: normal;
	src:url("../fonts/NotoSans-SemiCondensedBold.woff2") format("woff2"), /* Very Modern Browsers */
	  	url("../fonts/NotoSans-SemiCondensedBold.woff") format("woff"); /* Modern Browsers */
}